/* GLOBAL */
.company-logo {
  margin: 5px 5px 5px 10px;
  height: calc(40px + 1vmin);
  width: calc(32px + 1vmin);
}

h1 {
  color:#ffffff;
}

h2 {
  color:#012622;
}

/* NAVBAR */
.navbar-content-area {
  background-color: #012622;
  height: 64px;
  min-width: 98vw;
  padding-left: 1vw;
  padding-right: 1vw;
}

.navbar-containers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
}

.navbar-page-titles-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.toggle-line {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}

.navbar-links {
  z-index: 20;
  display: flex;
  flex-direction: row;
}

.navbar-page-titles {
  display: flex;
  font-display: flex;
  margin: 0px calc(5px + 1vmin) 0px calc(5px + 1vmin);
  font-size: calc(6px + 1vmin);
  font-weight: 300;
  text-decoration: none;
  color: #ffffff;
}

.exit-button {
  display: none;
  align-self: flex-end;
  margin-left: 10px;
  font-size: 35px;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-page-titles-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-toggle {
    display: flex;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links.active {
    display: flex;
    flex-direction: column;
    background-color: #012622;
    margin-top: 150px;
    padding: 10px;
    height: auto;
  }

  .navbar-page-titles {
    margin: 5px 0;
    font-size: 16px;
  }

  .exit-button {
    display: block;
  }

}

/* LANDING */
.landing-content-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color:#F4F3EE;
  padding: 25px 50px 25px 50px;
}

.landing-top-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.landing-top-content {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 50px 30px 15px 30px;
}

.landing-middle-content {
  display: flex;
  justify-content: left;
  margin: 15px 30px 15px 30px;
}

.landing-bottom-content {
  display: flex;
  justify-content: left;
  margin: 15px 30px 50px 30px;
}

.landing-name-title {
  color: #003b36;
  font-size: calc(60px + 1vmin);
  font-weight: 800;
  margin-bottom: 10px;
  margin-left: 60px;
  width: 100%;
  min-width: 150px;
}

.landing-subtitle {
  color: #003b36;
  font-size: calc(30px + 1vmin);
  font-weight: 450;
  font-style: italic;
  margin-bottom: 30px;
  margin-left: 60px;
  width: 100%;
  min-width: 150px;
}

.landing-top-title {
  color: #3B1F2B;
  font-size: calc(18px + 1vmin);
  font-weight: bolder;
  margin-bottom: 30px;
  margin-left: 60px;
  width: 40%;
  min-width: 150px;
}

.landing-top-text {
  color: #003b36;
  font-size: calc(10px + 1vmin);
  margin-left: 30px;
  margin-right: 30px;
  /* max-width: 550px; */
  width: 60%;
}

.landing-bottom-text {
  color: #003b36;
  font-size: calc(10px + 1vmin);
  margin-left: 30px;
  margin-right: 30px;
}

.landing-top-image-container {
  width: 100%;
  min-width: 200px;
  border-radius: 20px;
  margin: 30px;
}

.landing-tiles-image-container {
  width: 350px;
  border-radius: 20px;
}

.landing-tiles-container {
  background-color: #ffffff;
  width: 80%;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.landing-tile {
  margin: 35px 50px 35px 50px;
  display: flex;
  width: 350px;
  height: 250px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: #3B1F2B;
}

.landing-tile-text {
  position: absolute;
  display: flex;
  color: #F4F3EE;
  font-size: calc(26px + 1vmin);
  font-weight: bolder;
  max-width: 300px;
  text-align: center;
}

.landing-left {
  display: flex;
  width: 70%;
  justify-content: center;
}

.landing-left-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.landing-right {
  display: flex;
  width: 30%;
  min-width: 350px;
  justify-content: center;
  align-items: center;
}

/* FAQ */
.accordion {
  display: flex;
  flex-direction: column;
  width: 85vw;
}

.accordion-item {
  margin-bottom: 1rem;
}

.accordion-header {
  border: #ffffff;
  border-style: solid;
  border-radius: 12px;
  padding: 1rem;
  cursor: pointer;
}

.accordion-content {
  padding: 1rem;
  display: none;
}

.accordion-content.active {
  display: block;
}

/* 404 Error */
.error-link-text {
  color: #7de9ff;
  font-size: calc(30px + 1vmin);
  margin-left: 30px;
  margin-right: 30px;
  font-weight: bolder;
}

.error-top-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.error-top-text {
  color: #ffffff;
  font-size: calc(10px + 1vmin);
  margin-left: 30px;
  margin-right: 30px;
  /* max-width: 550px; */
  width: 60%;
  text-align: center;
}

/* TUTORIAL */

.tutorial-text {
  color: #ffffff;
  font-size: calc(10px + 1vmin);
  margin-top: 30px;
  margin-bottom: 30px;
}

/* LANDING POP-UP */
.modal-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color:#012622;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F4F3EE;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  color:#3B1F2B;
}
